<template>
    <div class="sports_bet_list">
        <table class="table_sports_bet" :class="{'border_inplay':item.isinplay == agentConst.YES}" v-for="item in betList">
            <tr>
                <th style="width: 5%">ID</th>
                <th style="width: 5%">BettingType</th>
                <th style="width: 8%">Memeber</th>
                <th style="width: 9%">Date</th>
                <th style="width: 8%">Betting Amount</th>
                <th style="width: 5%">Odds</th>
                <th style="width: 10%">Winning Amount</th>
                <th style="width: 7%">Result</th>
                <th style="width: 5%" colspan="2">Status</th>
            </tr>
            <tr>
                <td>{{item.id}}</td>
                <td>
                    <span v-if="item.type==agentConst.SportsBet.BET_TYPE_CROSS">Cross</span>
                    <span v-if="item.type==agentConst.SportsBet.BET_TYPE_1X2">1x2</span>
                    <span v-if="item.type==agentConst.SportsBet.BET_TYPE_HO">H/OU</span>
                    <span v-if="item.type==agentConst.SportsBet.BET_TYPE_SPECIAL">Speical</span>
                    <span v-if="item.type==agentConst.SportsBet.BET_TYPE_LIVE">Live</span>
                    <span v-if="item.isinplay==agentConst.YES" class="bg-blue">INPLAY</span>
                </td>
                <td>
                    <span class="badge badge-success">{{item.user.nickname}}</span>
                </td>
                <td>{{item.createTime|datef('MM/DD HH:mm:ss')}}</td>

                <td :class="{'text-blue':item.betCash >= 100000}">{{item.betCash|comma}}</td>
                <td>{{item.betOddsTotal}}</td>
                <td :class="{'text-blue':item.totalCash >= 100000}">{{(item.totalCash) | comma}}</td>
                <td>
                    <span class="" v-if="item.betResult == agentConst.SportsBet.BET_RESULT_WAITING">Watting</span>
                    <span class="badge badge-primary"
                          v-if="item.betResult == agentConst.SportsBet.BET_RESULT_WIN">Win</span>
                    <span  class="badge badge-danger" v-if="item.betResult == agentConst.SportsBet.BET_RESULT_LOSE">Fail</span>
                    <span class="badge badge-danger"
                          v-if="item.betResult == agentConst.SportsBet.BET_RESULT_SPECIALCASE">Special</span>
                    <span class="badge badge-danger" v-if="item.betResult == agentConst.SportsBet.BET_RESULT_CANCEL">Cancel</span>

                    <span class="" v-if="item.cancelbyuser == agentConst.YES">(Memebr)</span>

                    <span class="badge badge-warning" v-if="item.inited == agentConst.YES">(Init By Admin)</span>
                    <br/><span class="badge badge-warning" v-if="item.operator">{{item.operator}}</span>
                </td>
                <td colspan="2">
                    <span v-if="item.status==agentConst.SportsBet.STATUS_WAITING">Watting</span>
                    <span v-if="item.status==agentConst.SportsBet.STATUS_COMPLITE">Commplite</span>
                </td>
            </tr>
            <tr>
                <td colspan="11">
                    <table class="table_bet_games" v-if="item.isinplay === agentConst.NOT">
                        <tr>
                            <td class="text-center" style="width: 7%;">ID</td>
                            <td class="text-center" style="width: 10%;">Match Date</td>
                            <td class="text-center" style="width: 13%">League</td>
                            <td class="text-center" style="width: 25%">Home</td>
                            <td class="text-center" style="width: 8%;">
                                Draw/H/OU
                            </td>
                            <td class="text-center" style="width: 25%">Away</td>
                            <td class="text-center" style="width: 6%;">Game Reuslt</td>
                            <td class="text-center" style="width: 6%;">Betting Result</td>
                        </tr>
                        <tr v-for="bg in item.betInfoGameList"
                            :class="{'bg-warning':bg.game.autoclosed === agentConst.NOT}">
                            <td>
                                {{bg.game.id}}
                            </td>
                            <td>{{bg.game.startTime|datef('MM-DD HH:mm')}}</td>
                            <td style="text-align: left">
                                <img :src="bg.game.league.imageUrl"
                                     style="width: 28px;;vertical-align: middle">
                                <span style="display: inline-block;margin-left: 2px">{{bg.game.league.leagueName}}</span>
                            </td>
                            <td :class="{'bg-success':bg.selectedVal == agentConst.Game.GAME_SELECT_HOME}"
                                style="display: flex;justify-content: flex-start;align-items: center">
                                   <span style="width: 70%;text-align: left;padding-left: 3px"
                                         v-html="bg.game.homeTeamName"></span>
                                <span style="width: 30%;text-align: right;padding-right: 3px">
                                          <i v-if="bg.game.kind == agentConst.Game.GAME_KIND_HANDICAP"
                                             class="fa fa-h-square"></i>
                                            <i v-if="bg.game.kind == agentConst.Game.GAME_KIND_OVERUNDER"
                                               class="fa fa-arrow-circle-up"></i>
                                    {{bg.homeTeamOdds}}
                                </span>
                            </td>
                            <td :class="{'bg-success':bg.selectedVal == agentConst.Game.GAME_SELECT_DRAW}">
                                 <span v-if="bg.game.kind == agentConst.Game.GAME_KIND_1X2">
                                                            <span v-if="bg.drawOdds > 0">{{bg.drawOdds}}</span>
                                                            <span v-if="bg.drawOdds <= 0">vs</span>
                                                        </span>
                                <span class="text-red"
                                      v-if="bg.game.kind == agentConst.Game.GAME_KIND_HANDICAP">{{bg.handicapVal}}</span>
                                <span class="text-blue"
                                      v-if="bg.game.kind == agentConst.Game.GAME_KIND_OVERUNDER">{{bg.overunderVal}}</span>
                            </td>
                            <td :class="{'bg-success':bg.selectedVal == agentConst.Game.GAME_SELECT_AWAY}"
                                style="display: flex;justify-content: flex-start;align-items: center">

                                <span style="width: 30%;text-align: left;padding-left: 3px">
                                    {{bg.awayTeamOdds}}
                                     <i v-if="bg.game.kind == agentConst.Game.GAME_KIND_HANDICAP"
                                        class="fa fa-h-square"></i>
                                <i v-if="bg.game.kind == agentConst.Game.GAME_KIND_OVERUNDER"
                                   class="fa fa-arrow-circle-down"></i>

                                </span>
                                <span style="width: 70%;text-align: right;padding-right: 3px"
                                      v-html="bg.game.awayTeamName"></span>
                            </td>
                            <td>
                                <span v-if="bg.game.gameResult == agentConst.Game.GAME_RESULT_WATING">Watting</span>
                                <span v-if="bg.game.gameResult != agentConst.Game.GAME_RESULT_WATING">{{bg.game.homeTeamScore + ':'+bg.game.awayTeamScore}}</span>
                            </td>
                            <td>
                                <span v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_WAITING">Watting</span>
                                <span class="badge badge-primary"
                                      v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_WIN">Win</span>
                                <span  class="badge badge-danger"
                                       v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_LOSE">Fail</span>
                                <span class="badge badge-warning"
                                      v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_SPECIALCASE">Special Case</span>
                                <span class="badge badge-warning"
                                      v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_CANCEL">Cancel</span>
                            </td>
                        </tr>
                    </table>

                    <table class="table_bet_games" v-if="item.isinplay === agentConst.YES">
                        <tr>
                            <th class="text-center" style="width: 7%;">ID</th>
                            <th class="text-center" style="width: 7%;">게임ID</th>
                            <th class="text-center" style="width: 10%;">경기시간</th>
                            <th class="text-center" style="width: 13%">리그</th>
                            <th class="text-center">팀명</th>
                            <th>승부타임</th>
                            <th>구분</th>
                            <th>선택항목</th>
                            <th>선택배당</th>
                            <th>베팅시스코어</th>
                            <th>베팅결과</th>
                        </tr>
                        <tr v-for="bg in item.betInfoGameList">
                            <td class="text-center" style="width: 7%;">{{bg.id}}</td>
                            <td class="text-center" style="width: 7%;">{{bg.game.id}}</td>
                            <td class="text-center" style="width: 10%;">{{bg.game.startTime|datef('MM/DD HH:mm')}}
                            </td>
                            <td class="text-left" style="width: 13%">
                                <img :src="bg.game.league.imageUrl"
                                     style="width: 28px;vertical-align: middle">
                                <span>{{bg.game.league.leagueName}}</span>
                            </td>
                            <td>
                                <span>{{bg.game.homeTeamName}}</span>
                                <span style="color: deepskyblue"> vs </span>
                                <span>{{bg.game.awayTeamName}}</span>
                            </td>
                            <td>{{bg.ito1}}</td>
                            <td>{{bg.ito2}}</td>
                            <td class="text-blue" style="font-weight: bold">{{bg.ito3}}</td>
                            <td :class="{'bg-warning':bg.err == agentConst.YES}">{{bg.selectedOdds}}
                                <span v-if="bg.score != null">({{bg.score}})</span>
                                <span v-if="bg.err == agentConst.YES" class="text-danger">
                                                        <i class="fa fa-question-circle"></i>
                                                        <i class="fa fa-question-circle"></i>
                                                        <i class="fa fa-question-circle"></i>
                               </span>
                            </td>
                            <td>
                                <span v-if="bg.atscore" v-html="bg.atscore"></span>
                            </td>
                            <td>
                                <span v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_WAITING">대기중</span>
                                <span class="badge badge-primary"
                                      v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_WIN">당첨</span>
                                <span class="badge badge-danger"
                                      v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_LOSE">낙첨</span>
                                <span class="badge badge-warning"
                                      v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_SPECIALCASE">적특</span>
                                <span class="badge badge-warning"
                                      v-if="bg.betGameResult == agentConst.SportsBet.BET_RESULT_CANCEL">취소됨</span>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
        </table>

    </div>
</template>

<script>
    import agentConst from "../../common/agent/agentConst";

    export default {
        name: "SportsBetInfoComp2",
        data() {
            return {
                agentConst: agentConst,
                betList: [],
            }
        },
        props: {
            list: {
                type: Array,
                default() {
                    return [];
                }
            },
            form:{
                type:Number,
                default() {
                }
            }

        },
        methods: {

        },
        created() {
            console.log(this.betList)
        },
        watch: {
            list: {
                handler(newVal) {
                    this.betList = newVal
                },
                immediate: true,
                deep: true
            },
        }
    }
</script>

<style scoped>
    .sports_bet_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .table_sports_bet {
        width: 100%;
        margin-bottom: 10px;
    }

    .table_bet_games {
        width: 100%;
    }

    .table_bet_games td {
        line-height: 40px !important;
        border-bottom: 1px solid #cfcfd1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 2px;
    }
    .betinfogames td{
        border-bottom: 1px solid #c6c6c6;
        background-color: #c6c6c6!important;
    }
</style>