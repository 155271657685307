import httpManager from "../axios/httpManager";


export function getApplyList(search, pageNum, pageSize) {
    let url = '/applycash/list' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}
//파트너 출급내역 조회
export function getApplyExchangeForPartnerList(search, pageNum, pageSize) {
    let url = '/applycash/list_partner_exchange' + '?pageNum=' + pageNum + '&pageSize=' + pageSize;
    return httpManager.post(url, search);
}
export function updateApplyMemo(applyCash) {
    return httpManager.post('/applycash/update_memo', applyCash);
}

export function updateApplyStatus(applyCash) {
    return httpManager.post('/applycash/update_status', applyCash);
}

export function updateApplyDisplay(applyCash) {
    return httpManager.post('/applycash/update_display', applyCash);
}

export function updateApplyStatusCancelWrongResult(id) {
    return httpManager.get("/applycash/update_status_cancel_wrongresult?id=" + id + '&t=' + new Date().getTime());
}

export function updateApplyStatusCancel2Checking(id) {
    return httpManager.get("/applycash/update_status_cancel_2_checking?id=" + id + '&t=' + new Date().getTime());
}